import "../../Header.css";
import { useRecoilValue } from 'recoil';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { notifications } from '../atoms/notifications';
import { useUserState, useUserDispatch } from './UserContext';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Layout, Menu, Avatar, Badge, Typography, Popconfirm } from 'antd';

const { Text } = Typography;
const { Header: BaseHeader } = Layout;

const Header = ({ isDark = true }) => {
  const history = useHistory();
  const { user } = useUserState();
  const userDispatch = useUserDispatch();
  const notificationsValue = useRecoilValue(notifications)

  useEffect(() => {  }, [notificationsValue,])

  return (
    <BaseHeader className="header" style={{ position: 'fixed' }}>
      <div className="logo" />
        <Menu
          className="float-right back"
          theme="dark"
          mode="horizontal"
          selectable={false}
          onClick={async ({ key }) => {
            if (key === 'logout') {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              userDispatch({ type: 'delete' });
              history.push('/login');
            } else if (key === 'change-password') {
              history.push('/change-password');
            }
          }}
        >
        <Menu.SubMenu
          style={{ width: '200px', marginTop: '50px' }}
          className="float-right"
          title={
            <Text style={{ marginLeft: '1rem', color: '#ffffff' }}>
              {`${user?.name}`}
            </Text>
          }
          icon={
            <Badge dot>
              <Avatar shape="square" icon={<UserOutlined />} style={{ backgroundColor: '#ffffff', color: '#87030B', fontSize: '20px' }} />
            </Badge>
          }
        >
          <Popconfirm
            title="¿Estás seguro de cerrar sesión?"
            onConfirm={() => {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              userDispatch({ type: 'delete' });
              history.push('/login');
            }}
            onCancel={() => { }}
            okText="Aceptar"
            cancelText="Cancelar"
          >
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              Cerrar sesión
            </Menu.Item>
          </Popconfirm>
        </Menu.SubMenu>
      </Menu>
    </BaseHeader>
  );
};

export default Header;