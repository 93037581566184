import axios from 'axios';
import { configure } from 'axios-hooks';
import jwt from 'jsonwebtoken';
import LRUCache from 'lru-cache';

const {
  REACT_APP_IS_PROD: isProd,
  REACT_APP_DEV_BASE_URL: devBaseUrl,
  REACT_APP_PROD_BASE_URL: prodBaseUrl,
} = process.env;

const getNewToken = async () => {
  const oldRefreshToken = localStorage.getItem('refreshToken');

  if (oldRefreshToken !== null || oldRefreshToken !== undefined) {
    return this.post(
      `/auth/refresh`,
      {},
      {
        headers: {
          Refreshtoken: oldRefreshToken,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      },
    )
      .then((response) => {
        const {
          access_token: accessToken,
          refresh_token: refreshToken,
        } = response.data;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        return accessToken;
      })
      .catch(() => undefined);
  }
  return null;
};

const isTokenExpired = () => {
  const token = localStorage.getItem('accessToken');
  if (token === null || token === undefined) {
    return null;
  }
  const decodedToken = jwt.decode(token);
  const dateNow = new Date();
  if (decodedToken?.exp < dateNow.getTime()) {
    return false;
  }
  return true;
};

const setRefreshTokenInterceptor = async (config) => {
  try {
    const isExpired = isTokenExpired();
    if (isExpired === true) {
      const token = await getNewToken();
      if (token) {
        localStorage.setItem('accessToken', token);
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }
    }
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken != null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    }
  } catch (err) {
  }
  return config;
};

class ApiClient {
  constructor() {
    if (this?.axiosInstance === undefined) {
      this.axiosInstance = axios.create({
        baseURL: isProd === 'true' ? prodBaseUrl : devBaseUrl,
        headers: {
          Authorization: localStorage.getItem('accessToken')
            ? `Bearer ${localStorage.getItem('accessToken')}`
            : undefined,
        },
      });
      // interceptor request
      this.axiosInstance.interceptors.request.use(
        async function (config) {
          const newConfig = await setRefreshTokenInterceptor(config);
          return newConfig;
        },
        (error) => Promise.reject(error),
      );
      // interceptor response
      this.axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          // Do something with response error
          return Promise.reject(error);
        },
      );
    }
    return this;
  }

  setAccessTokenHeader() {
    this.axiosInstance.defaults.headers.Authorization = localStorage.getItem(
      'accessToken',
    )
      ? `Bearer ${localStorage.getItem('accessToken')}`
      : undefined;
    configure({ axios: this.axiosInstance, cache: new LRUCache({ max: 10 }) });
  }
}

export default ApiClient;