import React from 'react'
import logo from '../../logo.png';
import { Typography, Row } from 'antd';
import CenterLayout from '../layouts/CenterLayout';

const { Title } = Typography;

const LoadingView = () => {
  return (
    <CenterLayout>
      <Row>
      <img
        src={logo}
        fill="#7C7AAD"
        alt="Logo PTSMF"
        style={{
          width: '100%',
          maxWidth: '294px',
          aspectRatio: "initial",
          height: '14rem',
          display: 'block',
          margin: '0rem auto 1rem'
        }}
      />
      <Title level={4} style={{display:"block", width: '100%', textAlign: 'center'}}>Cargando</Title>
      </Row>
    </CenterLayout>
  )
}

export default LoadingView