import "../../SiderLayout.css";
import logo from '../../logoB.png';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import Header from '../components/Header';
import { SidebarData } from './SidebarData';
import { MenuOutlined } from '@ant-design/icons';
import { Layout, Typography, Button } from 'antd';
import logoMakesoft from '../../logo_makesoft.png';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';

const { Text } = Typography;
const { Footer, Content } = Layout;

const SiderLayout = ({ children, fullscreenContent = true }) => {

  const currentDate = new Date();
  const [collapsed,] = useState(true);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div className='navbar'>
        <Link to='#' className='menu-bars'>
          <Button className='icon-style' icon={<MenuOutlined />} onClick={showSidebar} ></Button>
        </Link>
        <img
          alt="ptsmf_logo"
          src={logo}
          className={
            collapsed ? ' logo logo-collapsed' : 'logo'
          }
          height="136px"
          style={{ marginTop: '30px', marginBottom: '30px' }}
        />
      </div>
      <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className='nav-menu-items' onClick={showSidebar}>
          <li className='navbar-toggle'>
            <Link to='#' className='menu-bars'></Link>
          </li>
          {SidebarData.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  {item.icon}
                  <span style={{ marginLeft: '16px' }}>{item.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <Header />
      <Content className={fullscreenContent ? 'fullscreen-container' : null}>
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'></Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span style={{ marginLeft: '16px' }}>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>

        </nav>
      </Content>
      <Footer>
        <Text className="float-left">
          PTSMF PENTATRANSPORTES {currentDate.getFullYear()}
        </Text>
        <Text className="float-right">
          <Text>Desarrollado por: </Text>
          <img alt="makesoft_logo" src={logoMakesoft} height={20} paddingTop={30} />
        </Text>
      </Footer>
    </>
  );
};




export default SiderLayout;