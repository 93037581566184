import React from 'react';
import { HomeFilled, IdcardOutlined, UserOutlined, TagsOutlined, ReconciliationOutlined, InboxOutlined, FieldNumberOutlined } from '@ant-design/icons';

export const SidebarData = [

  
    {
      title: 'Inicio',
      path: '/home',
      icon: <HomeFilled />,
      cName: 'nav-text'
    },
    // {
    //   title: 'Viajes',
    //   path: '/tours',
    //   icon: <TagsOutlined />,
    //   cName: 'nav-text'
    // },
    {
      title: 'Usuarios',
      path: '/users',
      icon: <UserOutlined />,
      cName: 'nav-text'
    },
    // {
    //   title: 'Operadores',
    //   icon: <IdcardOutlined />,
    //   cName: 'nav-text'
    // },
    {
        title: 'Vales',
        path: '/tours',
        icon: <TagsOutlined />,
        cName: 'nav-text'
      },
    {
      title: 'Clientes',
      path: '/clients',
      icon: <ReconciliationOutlined />,
      cName: 'nav-text'
    },
    // {
    //   title: 'Unidades',
    //   path: '/units',
    //   icon: <FieldNumberOutlined />,
    //   cName: 'nav-text'
    // },
    // {
    //   title: 'Remolques',
    //   path: '/truckboxes',
    //   icon: <InboxOutlined />,
    //   cName: 'nav-text'
    // },
  ];
  