import React from 'react';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const UserReducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      return { user: action.user };
    }
    case 'delete': {
      return { user: undefined };
    }
    default: {
      throw Error(`Unhandled action type ${action.type}`);
    }
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(UserReducer, { user: undefined });
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = () => {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
};

const useUserDispatch = () => {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useUserState, useUserDispatch };