import React from 'react';
import { Result } from 'antd';
import SiderLayout from '../layouts/SiderLayout';

const NoPermissionError = () => {

  return (
    <SiderLayout>
      {/* <PageHeader
        title={t('common:sections.error')}
        className="site-page-header has-strong-text"
      /> */}
      <Result
        // status="403"
        // title={t('common:messages.error-no-permissions')}
        // style={{ marginTop: '4rem' }}
      />
    </SiderLayout>
  );
};

export default NoPermissionError;