import React from 'react';
import { ConfigProvider } from 'antd';
import Authentication from './Authentication';
import ApiClient from './shared/api/ApiClient';
import { configure } from 'axios-hooks';
import LRUCache from 'lru-cache';

const apiClient = new ApiClient();
apiClient.setAccessTokenHeader();
configure({
  axios: apiClient.axiosInstance,
  cache: new LRUCache({ max: 10 }),
});
apiClient.setAccessTokenHeader();
const App = () => {

  const validateMessages = {
    required: "Este campo es requerido",
    types: {
      number: " no es un número válido",
    },
  };
  
  return (
    <ConfigProvider form={{ validateMessages }}>
      <Authentication />
    </ConfigProvider>
  );
};

export default App;