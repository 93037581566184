import useAxios from 'axios-hooks';
import React, { useEffect } from 'react';
import { useUserDispatch } from './UserContext';
import { Redirect, Route } from 'react-router-dom';
import NoPermissionError from './NoPermissionError';
import LoadingView from './LoadingView';

const ProtectedRoute = ({
  component: Component,
  roles = ['employee', "1", 'owner', 1],
  ...rest
}) => {
  const userDispatch = useUserDispatch();

  const [
    {
      data: dataUser,
      loading: loadingUser,
      error: errorUser,
      response: responseUser,
    },
    getUser,
  ] = useAxios('/auth/me');

  useEffect(() => {
    getUser().catch((error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }
    });
  }, [getUser]);

  useEffect(() => {
    if (responseUser != null && responseUser.status === 200 && dataUser) {
      userDispatch({ type: 'set', user: dataUser });
    }
  }, [dataUser, userDispatch, responseUser?.status, responseUser]);

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={() => {
        if (dataUser !== undefined && !roles.includes(dataUser?.rol_id))
          return <NoPermissionError />;
        if (loadingUser) return <LoadingView />;
        if (errorUser) return <Redirect to="/login" />;
        return <Component />;
      }}
    />
  );
};

export default ProtectedRoute;