import React from 'react';
import { UserProvider } from './shared/components/UserContext';
import Navigation from './Navigation';

const Authentication = () => {
  /**
   * User provider that serves the user object
   * Navigation that has all the routes available
   */

  return (
    <UserProvider>
      <Navigation />
    </UserProvider>
  );
};

export default Authentication;