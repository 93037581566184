import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './shared/components/ProtectedRoute';
import LoadingView from './shared/components/LoadingView';

const Login = React.lazy(() => import('./Login/components/Login'));
const RegisterUser = React.lazy(() => import('./Login/components/Register'));

const Dashboard = React.lazy(() => import('./Dashboard/components/Dashboard'));
const Users = React.lazy(() => import('./Users/components/Users'));
const Drivers = React.lazy(() => import('./Drivers/components/Drivers'));
const Units = React.lazy(() => import('./Units/components/Units'));
const Clients = React.lazy(() => import('./Clients/components/Clients'));
const Tours = React.lazy(() => import('./Tours/components/Tours'));
const Routes = React.lazy(() => import('./Routes/components/Routes'));
const Truckboxes = React.lazy(() => import('./Truckboxes/components/Truckboxes'));
const LoginTour = React.lazy(() => import('./LoginTour/components/LoginTour'));
const InitialTour = React.lazy(() => import('./LoginTour/components/InitialTour'));
const FinalTour = React.lazy(() => import('./LoginTour/components/FinalTour'));
const Settings = React.lazy(() => import('./Settings/components/Settings'));
 
const Navigation = () => (
  <Suspense fallback={<LoadingView/>}>
    <Router>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/register" component={RegisterUser} exact />
        
        <Route path="/login/tour" component={LoginTour} exact />
        <Route path="/initial/tour" component={InitialTour} exact />
        <Route path="/final/tour/:tour_id" component={FinalTour} exact />
        <Route path="/finish/tour" component={Login} exact />
        <Route path="/logout" component={Login} exact />
        <ProtectedRoute path="/analytics" component={Dashboard} exact />
        <ProtectedRoute path="/users" component={Users} exact />
        <ProtectedRoute path="/tours" component={Tours} exact />
        <ProtectedRoute path="/routes" component={Routes} exact />
        <ProtectedRoute path="/drivers" component={Drivers} exact />
        <ProtectedRoute path="/units" component={Units} exact />
        <ProtectedRoute path="/clients" component={Clients} exact />
        <ProtectedRoute path="/truckboxes" component={Truckboxes} exact />
        <ProtectedRoute path="/settings" component={Settings} exact />
        <ProtectedRoute path={['/', '/home']} component={Dashboard} />
      </Switch>
    </Router>
  </Suspense>
);

export default Navigation;