import React from 'react';
import { Layout, Row } from 'antd';

const { Content } = Layout;

const CenterLayout = ({ children, fullscreenContent = true }) => (
  <Layout>
    <Content>
      <Row
        // className={fullscreenContent ? 'fullscreen-container' : null}
        type="flex"
        justify="center" 
        align="middle"
        // style={{ minHeight: '100vh', background:'#ffffff' }}
      >
        {children}
      </Row>
    </Content>
  </Layout>
);

export default CenterLayout;